import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import OnlineShoppingImg from 'images/blog-images/online-shopping.png';
import FactoryDifficultiesImg from 'images/blog-images/factory-difficulties.png';
import FactoryDifficultiesFeatured from 'images/featured-image/factory-difficulties-featured.png';
import FactoryStep from 'images/blog-images/traditional-factory-steps.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query DifficultiesForFactoriesImg {
      file(relativePath: { eq: "online-shopping.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="factory difficulties"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="What are the Difficulties for Traditional Factories and Traders to Transform Online Ecommerce? | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-what-are-the-difficulties" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "What are the Difficulties for Traditional Factories and Traders to Transform Online Ecommerce?",
              content: intl.formatMessage({ id: "blog-post-meta-title-what-are-the-difficulties" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: FactoryDifficultiesFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="what-are-the-difficulties-for-traditional-factories-and-traders-to-transform-online-ecommerce"
        title="What are the Difficulties for Traditional Factories and Traders to Transform Online Ecommerce?"
        date="2020-11-02"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <h5 className="no-top">
              <strong>
                Why do factories and wholesale traders want to transform B2C eCommerce?
              </strong>
            </h5>

            <p>
              In recent years, more and more factories and wholesale traders have begun to transform from a wholesale (2B) business to a retail (2C) business. One of the important reasons is that  because domestic eCommerce has been very popular in recent years, many business owners began to think that eCommerce can also “make big money”.
            </p>

            <p>
              Another reason is that B2B business is obviously affected by B2C business. Alibaba International has also shown a trend of small batches and even supports drop shipping. Nowadays, fewer people would accept the order model that required thousands of pieces of goods. The demand in each market is limited. When many consumers can directly buy things online, the frequency of offline purchases will be reduced.
            </p>

            <p>
              When consumers do not go to shopping malls, all kinds of merchants dare not stock up like in the past, which will affect the orders of factories. In the past, every large merchant that dared to place orders in large quantities from the factory was because there were countless small merchants behind it. In addition to this year's epidemic of COVID-19, many countries have blocked offline shopping malls, which undoubtedly intensified the development of online eCommerce.
            </p>

            <Image src={FactoryStep} className="" alt="traditional factory step"/>

            <p>
              The image above shows a traditional channel relationship, which is why in the past offline things were very expensive, because middlemen increased their prices. Today's online product prices are relatively cheap, because middlemen are "cut" or "reduced". More and more brands are beginning to face consumers directly, so they can achieve extremely low prices.
            </p>

            <p>
              In addition, the accounting period of traditional channels is relatively long. As shown in the figure above, because there are layers of channel providers, it means that the payment may need to be pressed layer by layer. This model was not affected by eCommerce in the past, and factories can still bear it when profits are high. But now that profits are getting lower and lower, and long billing periods are required, the factory may not be able to "endure" it.
            </p>

            <p>
              The eCommerce payment cycle is stable and short, just like eCommerce platforms in Southeast Asia generally paid on a weekly, half-month, or one-month basis. Compared with the traditional offline months, or even half a year and a year, the billing period is obviously much better.
            </p>

            <Image src={OnlineShoppingImg} className="" alt="OnlineShopping"/>

            <h5>
              <strong>
                So what are the difficulties when factories and wholesale traders want to transform?
              </strong>
            </h5>

            <OrderedList>
              <li>
                Difficulty in building an eCommerce team <br />
                Because of the large and costly site requirements, the general factory will choose to be in a relatively remote area, or in the second and third tier or even more outside cities. The remoteness of the site means inconvenience to transportation, which in turn makes people difficult. The environment of the factory is generally worse, because there may be workshops, machinery and other things, and young people don't like this environment.
              </li>

              <li>
                Unable to adapt to the spot demand and cumbersome process of eCommerce <br />
                The key to online store operations is "fast delivery", which means stocking up for inventory in advance. However, in the past, general factories used mass production and sales models (how many orders are produced, how many goods are produced). Because of the production process, raw material procurement, worker assembly and other reasons, each production requires a certain quantity (usually a single SKU several Hundreds or thousands). In the early stage of the eCommerce store, there is no certain order, and the factory does not just produce and make inventory.
                <br />
                In addition, the eCommerce retail process is relatively cumbersome. Customer service needs to take orders one by one to solve buyer's questions in fear of getting a bad review from customers. In the past, factories were accustomed to the simple mode of drinking a few cups of tea with customers and visiting the factory to get a large order.
              </li>

              <Image src={FactoryDifficultiesImg} className="" alt="factory difficulties"/>

              <li>
                Offline channel restrictions <br />
                Some brand owners have been cultivating offline channels for many years. Once online sales are launched, they will affect the interests of offline channels and be restrained by them. Mike has worked with a company before. As soon as he became an eCommerce company, offline channel providers threatened the brand to return or delay the payment.
              </li>

              <li>
                Fierce competition <br />
                Even without the above three factors, traditional factories/traders have established a good team and have spot inventory, and there is no restriction on offline channels. When they start to do eCommerce, they will also face competition from a group of sellers, and they must be able to compete before they can get a certain profit. When the competition is fiercer, the profit is lower, which may make them feel afraid to invest resources to solve the above problems.
              </li>

            </OrderedList>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;